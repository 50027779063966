import React from 'react';
import {graphql} from 'gatsby';
import {Page} from '../components/Page';
import PageHeader from '../components/PageHeader';
import {Markdown} from '../components/Markdown';
import {Helmet} from "react-helmet";

export default function About({data: {about}}) {
	return <Page>
		<Helmet>
			<title>{about.titleSEO}</title>
			<meta name="description" content={about.descriptionSEO} />
			{/*			<link rel="canonical" href={'https://www.icit.com.au/services/' + service.slug}  />*/}
		</Helmet>
		<PageHeader>{about.title}</PageHeader>
		<Markdown>
			{about.bodyText}
		</Markdown>
	</Page>;
}

export const query = graphql`
    query {
        about {
        	title
        	bodyText
			titleSEO
			descriptionSEO
        }
    }
`;
